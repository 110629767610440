<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('SUPERADMIN.SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :key="keyTable"
      v-model="selected"
      :headers="headers"
      :items="productList"
      :search="search"
      item-key="productId"
      multi-sort
      :single-select="true"
    >
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at | moment(campus.date_format) }}
      </template>
      <template v-slot:[`item.ACTIONS`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="showProduct(item)"
              class="tooltip-info"
            >
              <font-awesome-icon icon="eye" />
            </v-btn>
          </template>
          <span>{{ $t("SUPERADMIN.PRODUCT_DETAIL") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="publish(item)"
              class="tooltip-info"
            >
              <font-awesome-icon icon="check-circle" />
            </v-btn>
          </template>
          <span>{{ $t("SUPERADMIN.PRODUCT_APPROVE") }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="notifyIssue(item)"
              class="tooltip-info"
            >
              <font-awesome-icon icon="bell" />
            </v-btn>
          </template>
          <span>{{ $t("SUPERADMIN.PRODUCT_SEND_NOTIFICATION") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="showDetails" max-width="900px">
      <v-card>
        <v-card-text>
          <Loader></Loader>
          <v-row class="mt-0 mr-0 ml-0 mb-0 pt-0 pr-0 pl-0 pb-0" justify="end">
            <v-col cols="12">
              <v-btn
                @click="showDetails = false"
                depressed
                class="mr-2"
                color="red"
                dark
              >
                {{ $t("GENERAL.CLOSE") }}
              </v-btn>
              <v-btn
                @click="notifyIssue(productSelected)"
                depressed
                class="mr-2"
                color="grey"
                dark
              >
                {{ $t("GENERAL.REPORT_ISSUE") }}
              </v-btn>
              <v-btn
                @click="publish(productSelected)"
                class="mr-2"
                depressed
                color="success"
              >
                {{ $t("GENERAL.PUBLISH") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <ProductCourse
                v-if="productType === 1"
                :isComponent="true"
                :propProductId="productId"
                :key="productKey"
              />
              <ProductRoom
                v-if="productType === 2"
                :propProductId="productId"
                :key="productKey"
                :isComponent="true"
              />
              <ProductService
                v-if="productType === 3"
                :propProductId="productId"
                :key="productKey"
                :isComponent="true"
              />
              <ProductPackage
                v-if="productType === 6"
                :propProductId="productId"
                :key="productKey"
                :isComponent="true"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showFormNotify" max-width="350" eager>
      <v-card>
        <v-card-title class="headline">
          {{ $t("SUPERADMIN.PRODUCT_SEND_NOTIFICATION") }}
        </v-card-title>

        <v-card-text>
          <validation-observer ref="observerNotify">
            <v-form>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                rules="required"
              >
                <v-textarea
                  v-model="notify.description"
                  outlined
                  :error-messages="errors"
                  :label="$t('PRODUCTS_ADDONS.DESCRIPTION')"
                  required
                ></v-textarea>
              </validation-provider>
            </v-form>
          </validation-observer>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red darken-1" text @click="showFormNotify = false">
            {{ $t("GENERAL.CANCEL") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="requestNotify()">
            {{ $t("GENERAL.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmPublish" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.PUBLISH.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="showConfirmPublish = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="
              requestPublish();
              showConfirmPublish = false;
            "
          >
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCourse from "@/view/pages/productAddon/courses/Form";
import ProductRoom from "@/view/pages/productAddon/rooms/Form";
import ProductService from "@/view/pages/productAddon/services/Form";
import ProductPackage from "@/view/pages/packages/Edit";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Loader from "@/view/layout/Loader";

export default {
  components: {
    ProductCourse,
    ProductRoom,
    ProductService,
    ProductPackage,
    ValidationObserver,
    ValidationProvider,
    Loader,
  },
  data() {
    return {
      search: "",
      selected: [],
      headers: [
        {
          text: this.$i18n.t("SUPERADMIN.PRODUCT_NAME"),
          value: "name",
        },
        {
          text: this.$i18n.t("SUPERADMIN.PRODUCT_CAMPUS_LODGING"),
          value: "campus.name",
        },
        {
          text: this.$i18n.t("SUPERADMIN.PRODUCT_CITY_COUNTRY"),
          value: "campus.google_place.translation",
        },
        {
          text: this.$i18n.t("SUPERADMIN.PRODUCT_TYPE_PRODUCT"),
          value: "product_type.code",
        },
        {
          text: this.$i18n.t("SUPERADMIN.PRODUCT_CATEGORY"),
          value: "category",
        },
        {
          text: this.$i18n.t("SUPERADMIN.PRODUCT_DATE_REQUEST_PUBLISH"),
          value: "updated_at",
        },
        { text: this.$i18n.t("SUPERADMIN.ACTIONS"), value: "ACTIONS" },
      ],
      listProducts: [],
      showConfirmPublish: false,
      showFormNotify: false,
      showDetails: false,
      productType: null,
      productId: null,
      productSelected: null,
      notify: {
        description: null,
      },
      productKey: 0,
      keyTable: 0,
    };
  },
  async mounted() {
    const validation = {
      isReady: 1,
      isPublish: 0,
    };
    this.getProducts(validation).then();
  },
  methods: {
    ...mapActions([
      "getProducts",
      "publishProduct",
      "notifyProduct",
      "getListFees",
      "getAddons",
    ]),
    async showProduct(product) {
      this.selected = [product];
      this.productSelected = product;
      await this.getListFees({ campusId: product.campusId });
      await this.getAddons({ campusId: product.campusId });
      this.productType = product.productTypeId;
      this.productId = product.productId;
      this.productKey += 1;
      this.showDetails = true;
    },
    publish(product) {
      this.productSelected = product;
      this.productId = product.productId;
      this.showConfirmPublish = true;
    },
    notifyIssue(product) {
      this.productSelected = product;
      this.productId = product.productId;
      this.showFormNotify = true;
    },
    async requestPublish() {
      const result = await this.publishProduct(this.productId);
      if (result.success) {
        const indexList = this.listProducts.indexOf(this.productSelected);
        this.listProducts.splice(indexList, 1);
        this.showDetails = false;
        this.$bvToast.toast(this.$t("SUPERADMIN.PRODUCT_PUBLISHED"), {
          title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
          variant: "success",
          solid: true,
        });
      }
    },
    async requestNotify() {
      const resultValidation = await this.$refs.observerNotify.validate();

      if (resultValidation) {
        const resultNotify = await this.notifyProduct({
          product: this.productSelected,
          notify: this.notify,
        });
        if (resultNotify.success) {
          this.showFormNotify = false;
          this.showDetails = false;
          this.keyTable += 1;
          this.$bvToast.toast(this.$t("SUPERADMIN.PRODUCT_NOTIFIED"), {
            title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
            variant: "success",
            solid: true,
          });
        }
      } else {
        this.$bvToast.toast(this.$t("GENERAL.VALIDATE_FIELDS"), {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["products", "campus"]),
    productList() {
      return this.products
        ?.filter(itemFilter => {
          switch (itemFilter.product_type.code) {
            case "COURSE":
              return itemFilter.product_course;
            case "ROOM":
              return itemFilter.product_room;
            case "SERVICE":
              return itemFilter.product_service;
            case "PACKAGE":
              return itemFilter.product_package;
            default:
              return itemFilter.product_course;
          }
        })
        .map(item => {
          switch (item.product_type.code) {
            case "COURSE":
              item.category = item.product_course.category.name;
              return item;
            case "ROOM":
              item.category = item.product_room.category.codeName;
              return item;
            case "SERVICE":
              item.category = item.product_service.category.name;
              return item;
            case "PACKAGE":
              item.category = "N/A";
              return item;
            default:
              item.category = "N/A";
              return item;
          }
        });
    },
  },
  watch: {
    showFormNotify: function() {
      this.notify.description = null;
      this.$refs.observerNotify.reset();
    },
  },
};
</script>
