<template>
  <v-main>
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pt-0 pb-0">
        <v-card>
          <v-card-text>
            <v-row no-gutters justify="space-around">
              <v-col cols="12" md="12">
                <TableProduct />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableProduct from "@/view/pages/superadmin/product/components/TableProduct";

export default {
  name: "list-products",
  components: { TableProduct },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SUPERADMIN.TITLE_PRODUCT") },
    ]);
  },
};
</script>
